import React, { useEffect, useState } from "react";
import { Select } from "antd";

const AutoSelectOnTab = ({ options = [], placeholder = '', value = '', tabIndex, onChange, handleTabPress, style, autoFocus, size }) => {

    const [newValue, setNewValue] = useState();
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [searchText, setSearchText] = useState('')
    const [isActive, setActive] = useState(false)

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])
    
    useEffect(() => {
        setNewValue(value)
    }, [value])

    // Handle the search input
    const handleSearch = async (searchText) => {
        setSearchText(searchText)
        if (searchText) {
            const newFilteredOptions = await options.filter((option) =>
                option.label.toLowerCase().includes(searchText.toLowerCase())
            );
            setFilteredOptions(newFilteredOptions);

            // Reset index if the search text doesn't match the current value
            const matchIndex = await newFilteredOptions.indexOf(searchText);
            setCurrentIndex(matchIndex >= 0 ? matchIndex : 0);

            setNewValue(searchText);
        }
    };

    // Handle keyboard navigation
    const handleKeyDown = (event) => {
        if (filteredOptions.length > 0) {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                setActive(true)
                const newIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % filteredOptions.length;
                setCurrentIndex(newIndex);
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                setActive(true)
                const newIndex = currentIndex === -1 ? 0 :
                    (currentIndex - 1 + filteredOptions.length) % filteredOptions.length;
                setCurrentIndex(newIndex);
            } else if (event.key === "Tab") {
                if (currentIndex >= 0 && isActive) {
                    setNewValue(filteredOptions[currentIndex].value);
                    handleTabPress(filteredOptions[currentIndex].value)
                    setSearchText('')
                    setActive(false)
                }
            }
        }
    };

    // Handle selection
    const handleSelect = (selectedValue) => {
        setNewValue(selectedValue);
        setFilteredOptions(options); // Reset to all options after selection
        setCurrentIndex(0); // Reset index
        setSearchText('')
    };

    return (
        <Select
            options={options}
            tabIndex={tabIndex}
            placeholder={placeholder}
            value={newValue ? newValue : undefined}
            showSearch
            autoFocus={autoFocus}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onKeyDown={handleKeyDown}
            onChange={onChange}
            filterOption={(input, option) =>
                option.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
            style={style}
            size={size ? size : 'middle'}
        />
    )
}

export default AutoSelectOnTab