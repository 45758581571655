import { getPusherInstance } from "./pusher";

const broadcastChannel = new BroadcastChannel("pusher-connection-sync");
let pusherInstance = null;
let channelInstances = {};

// Handle messages from other tabs
broadcastChannel.onmessage = (event) => {
    if (event.data.type === "pusher-disconnected") {
        localStorage.removeItem("pusher-active");
    } else if (event.data.type === "pusher-connected") {
        console.log("Pusher instance is active in another tab.");
    } else if (event.data.type === "pusher-event") {
        // Handle broadcasted events from other tabs
        const { channelName, eventName, data } = event.data.payload;
        console.log(`Event received via broadcast for ${channelName} - ${eventName}:`, data);

        // If a callback exists for the event, invoke it
        if (channelInstances[channelName]?.callbacks[eventName]) {
            channelInstances[channelName].callbacks[eventName](data);
        }
    }
};

// Ensure only one Pusher instance is active
const ensurePusherInstance = () => {
    if (!pusherInstance) {
        pusherInstance = getPusherInstance();
        localStorage.setItem("pusher-active", "true");
    }
    return pusherInstance;
};

// Initialize Pusher and manage channel subscriptions
export function initializePusher(channelName, eventName, callback) {
    const isPusherActive = localStorage.getItem("pusher-active");

    // If Pusher is already active in another tab, just bind to the broadcast events
    if (isPusherActive && !pusherInstance) {
        if (!channelInstances[channelName]) {
            channelInstances[channelName] = { callbacks: {} };
        }
        channelInstances[channelName].callbacks[eventName] = callback;
        return;
    }

    // Create or reuse the Pusher instance
    const pusher = ensurePusherInstance();

    // If this is the first subscription, create the channel
    if (!channelInstances[channelName]) {
        const channel = pusher.subscribe(channelName);
        channelInstances[channelName] = { channel, callbacks: {} };
    }

    // Bind the event to the channel
    channelInstances[channelName].callbacks[eventName] = callback;
    channelInstances[channelName].channel.bind(eventName, (data) => {
        console.log(`Event data received for ${channelName} - ${eventName}:`, data);

        // Invoke the callback for this tab
        callback(data);

        // Broadcast the event to other tabs
        broadcastChannel.postMessage({
            type: "pusher-event",
            payload: { channelName, eventName, data },
        });
    });

    // Notify other tabs that this tab is now active
    broadcastChannel.postMessage({ type: "pusher-connected" });

    // Clean up when the tab is closed
    window.addEventListener("beforeunload", () => {
        if (pusherInstance) {
            broadcastChannel.postMessage({ type: "pusher-disconnected" });
            Object.keys(channelInstances).forEach((channelName) => {
                pusherInstance.unsubscribe(channelName);
                delete channelInstances[channelName];
            });
            localStorage.removeItem("pusher-active");
        }
    });
}

// Unbind events when navigating away
export function unbindPusher(channelName, eventName) {
    const instance = channelInstances[channelName];
    if (instance?.channel) {
        instance.channel.unbind(eventName);
        delete instance.callbacks[eventName];

        // If no callbacks remain, unsubscribe from the channel
        if (Object.keys(instance.callbacks).length === 0) {
            instance.channel.unsubscribe(channelName);
            delete channelInstances[channelName];
        }
    }
}
